<template>
  <div style="width: 65%; justify-content: center; margin: 0 auto;">
    <md-card>
      <md-card-header>
        <div class="md-layout md-size-90 md-alignment-center">
          <p class="md-headline">Terms of Use - All My Faves Inc. / Updated: 17/06/2023</p>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            <p>
              All My Faves provides its services to you subject to the following conditions. By visiting allmyfaves.com,
              you accept these conditions. Please read them carefully. All My Faves Inc. is not responsible for the
              content or
              the privacy policies of websites to which it may link.
            </p>
          </div>
        </div>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            <p class="md-subheading bold">
              Privacy Policy
            </p>
            <p class="">
              Please preview our
              <router-link v-if="authType === 'USER'" tag="div" :to="{ name: 'policy' }">
                Privacy Policy,
              </router-link>
              which governs your use of the allmyfaves.com website, to understand our practices.
            </p>
            <p class="md-subheading bold">
              Use of Logos and Trademarks
            </p>
            <p>
              The logos that appear on All My Faves are of a company, organization, item, product, or event and are
              protected by copyright and/or trademark. It is believed that the use of low-resolution images of logos to
              illustrate the Company, organization, item, product, or event in question, to use a visual link directly to
              the Company, organization, item, or event associated with the respective links/logo, qualifies as fair use
              under Copyright Law. Any featured companies, organizations, items, or events on the allmyfaves.com website
              that wish to be removed from All My Faves may contact us at info@allmyfaves.com, and we shall remove these
              upon request.
            </p>
            <p class="md-subheading bold">
              User Responsibilities and Guidelines
            </p>
            <ul>
              <li>
                We reserve the right to modify or terminate the All My Faves service for any reason, without notice, at
                any time.
              </li>
              <li>
                You must be at least 13 years or older to sign up for allmyfaves.com.
              </li>
              <li>
                Initiating or taking part in any malicious behavior towards All My Faves Inc., allmyfaves.com, its Users,
                or any third party via the Company's website or under your All My Faves account is strictly forbidden. Any
                malicious and/or obtrusive actions or behavior on the part of the User, as judged by All My Faves Inc.,
                shall result in the prompt termination of your All My Faves account until further notice.
              </li>
              <li>
                Creating, sharing, and/or submitting irrelevant or malicious email messages or 'spam' to any All My Faves
                members is strictly forbidden.
              </li>
              <li>
                We reserve the right to modify or terminate the All My Faves service for any reason, without notice, at
                any time.
              </li>
              <li>
                Any activity that takes place or is initiated under your All My Faves User name and/or account, including
                any content exchanged between and shared with other Users or any other third parties, is under your own
                responsibility.
              </li>
              <li>
                Transmitting any worms, viruses, or any malicious code is strictly forbidden.
              </li>
              <li>
                It is your responsibility as a User to safeguard your User name and password.
              </li>
              <li>
                It is strictly forbidden to use the All My Faves service for any illegal and/or unauthorized purpose.
                International Users agree to comply with all local laws associated with online conduct and appropriate
                content.
              </li>
              <li>
                Violating any laws in your jurisdiction (including but not limited to copyright laws) while using the All
                My Faves service is strictly forbidden. All My Faves' collection and use of personal information are
                subject to our Privacy Policy.
              </li>
              <li>
                Users are the sole persons responsible for online conduct, the links and sites they choose to add to their
                account, and any derived content and information thereof. The choice of sites added to the personalized
                All My Faves feature is entirely up to the User, and All My Faves Inc. and/or All My Faves shall not be
                responsible or held accountable for any inappropriate content as a result.
              </li>
              <li>
                Making changes to, adapting, hacking, or making use of the All My Faves service for any purpose or reason
                other than those described in this document or anywhere else on the website, including any false
                representation of All My Faves and/or association with All My Faves, is strictly forbidden. Offenders
                shall be prosecuted accordingly.
              </li>
              <li>
                All My Faves reserves the right to refuse service to anyone for any reason at any time, as directed by any
                applicable laws.
              </li>
              <li>
                All My Faves may, but is not obligated to, delete accounts and/or content that we deem inappropriate,
                malicious, illegal, obtrusive, harmful, abusive, or otherwise in violation of any of these Terms of Use.
              </li>
              <li>
                All My Faves reserves the right to change or delete User names for any reason as it sees fit, without any
                obligation to provide the respective Users with any notice.
              </li>
              <li>
                All My Faves reserves the right to change or delete User names on behalf of companies, businesses, or
                individuals for which those User names are a source of legal, registered trademark, or intellectual
                property dispute and/or claim.
              </li>
              <li>
                You understand and agree that by adding a site to your All My Faves personalized home page, you do not
                own, neither entirely nor partially, or maintain any copyright of the chosen site's trademark.
              </li>
            </ul>
            <p class="md-subheading bold">
              Games
            </p>
            <ul>
              <li>
                All My Faves holds no responsibility for any of the games featured on All My Faves or content thereof. All
                of the featured games were neither developed nor created by All My Faves.
              </li>
              <li>
                All My Faves cannot be held reliable for the content provided by and within each game.
              </li>
              <li>
                Games featured on All My Faves may contain violence, suggestive themes, crude humor, blood and gore,
                simulated gambling, and/or infrequent use of strong language. By proceeding to play the game, you agree
                that you have understood the respective game's disclaimers, if any, or any other warnings provided by the
                games' creators.
              </li>
              <li>
                All My Faves is not responsible for any vulgar, offensive, or abusive language or any other inappropriate
                expressive language that may offend individuals or groups in general.
              </li>
              <li>
                All visitors may enter and play multiplayer games at their own risk.
              </li>
              <li>
                Games featured on All My Faves are copyrighted property of their respective owner/developer. An
                owner/developer of a game who wishes to be removed from All My Faves may contact us at
                info@allmyfaves.com, and we shall remove it upon request.
              </li>
            </ul>
            <p class="md-subheading bold">
              Disclaimer of Warranties and Limitation of Liability
            </p>
            <p>
              THIS SITE IS PROVIDED BY ALL MY FAVES INC. ON AN "AS IS" AND "AS AVAILABLE" BASIS. ALLMYFAVES.COM MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPLICITLY, IMPLICITLY, OR IMPLIED, AS TO THE OPERATION OF THIS
              SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU HEREBY AGREE THAT YOUR
              USE OF THIS SITE IS AT YOUR OWN RISK.
            </p>
            <p>
              TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, ALLMYFAVES.COM DISCLAIMS ALL WARRANTIES, EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE. ALLMYFAVES.COM DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM ALLMYFAVES.COM ARE
              FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ALLMYFAVES.COM WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
              ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
              AND CONSEQUENTIAL DAMAGES.
            </p>
            <p>
              CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
              DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY
              NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
            </p>
            <p class="md-subheading bold">
              Affiliate Programs
            </p>
            <p>
              Some of the links throughout the site are affiliated. All My Faves participates in various affiliate
              programs, including the Amazon Associates Program and other similar programs. These programs are designed to
              provide a means for sites to earn advertising fees by advertising and linking to affiliated websites. As
              part of these affiliate programs, as an Amazon Associate, we earn commissions from qualifying purchases and
              referrals made through the links on the allmyfaves.com website.
            </p>
            <p class="md-subheading bold">
              Other
            </p>
            <p>
              All My Faves and you are independent entities, and nothing in the All My Faves Terms, or via the use of All
              My Faves, will create any partnership, joint venture, agency, franchise, sales representative, or employment
              relationship between All My Faves and you. These All My Faves Terms supersede any previous agreement related
              to your use of All My Faves.
            </p>
            <p class="md-subheading bold">
              Your Feedback
            </p>
            <p>
              All My Faves welcomes your continuous input regarding our Terms of Use & Privacy Policy or our provided
              services. You may send us your comments and responses to info@allmyfaves.com.
            </p>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'Terms',
};
</script>
